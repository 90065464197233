module.exports={
  "$id": "har.json#",
  "$schema": "http://json-schema.org/draft-06/schema#",
  "type": "object",
  "required": [
    "log"
  ],
  "properties": {
    "log": {
      "$ref": "log.json#"
    }
  }
}
