var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var historyItem_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\n<div class=\"revamp\">\n\t<div class=\"itemTitle\">\n\t\t<div class=\"icon ";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.contextOrFrameLookup(context, frame, "statusName")), env.opts.autoescape);
output += "\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") == 0) {
output += "\n\t\t\t\t<i class=\"fa fa-star\"/>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") == 4 || runtime.contextOrFrameLookup(context, frame, "status") == 6 || runtime.contextOrFrameLookup(context, frame, "status") == 8) {
output += "\n\t\t\t\t<i class=\"fa fa-check\"/>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") == 1) {
output += "\n\t\t\t\t<i class=\"fa fa-clock-o\"/>\n\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "status") == 5 || runtime.contextOrFrameLookup(context, frame, "status") == 2 || runtime.contextOrFrameLookup(context, frame, "status") == 7) {
output += "\n\t\t\t\t<i class=\"fa fa-minus\"/>\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t<i class=\"fa fa-times\"></i>\n\t\t\t";
;
}
;
}
;
}
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"status-text\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "statusName") == "New") {
output += "\n\t\t\t\tAwaiting Payment\n\t\t\t";
;
}
else {
output += "\n\t\t\t\tPurchase ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "statusName"), env.opts.autoescape);
output += "\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t</div>\n\n\t<div class=\"itemBox\">\n\t\t<div class=\"summary\">\n\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") == 0 || runtime.contextOrFrameLookup(context, frame, "status") == 2 && !runtime.contextOrFrameLookup(context, frame, "impersonating")) {
output += "\n\t\t\t\t<div class=\"paymentInstructions\">\n\t\t\t\t\t<i class=\"fa fa-info-circle\"/>\n\t\t\t\t\t<span><span class=\"payonline-link link\">Pay online </span>using a credit/cheque card, instant EFT, Masterpass (including Zapper) or SCode voucher.</span>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"infos\">\n\t\t\t\t<div class=\"totals\">\n\t\t\t\t\t<div><strong>Created:</strong> <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "created"), env.opts.autoescape);
output += "</span></div>\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "reference")) {
output += "\n\t\t\t\t\t\t<div><strong>Reference:</strong> <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "reference"), env.opts.autoescape);
output += "</span></div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<div><strong>Purchase:</strong> <span>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "description")), env.opts.autoescape);
output += "</span></div>\n\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<strong>Subtotal:</strong> <span>";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "subTotal") > 0?runtime.contextOrFrameLookup(context, frame, "symbol") + (lineno = 47, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "numFormat"), "numFormat", context, [runtime.contextOrFrameLookup(context, frame, "subTotal")])):"Free"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<strong>Credit Applied:</strong> <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "creditSign"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((env.getFilter("abs").call(context, runtime.contextOrFrameLookup(context, frame, "credit")) > 0?runtime.contextOrFrameLookup(context, frame, "symbol") + (lineno = 51, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "numFormat"), "numFormat", context, [env.getFilter("abs").call(context, runtime.contextOrFrameLookup(context, frame, "credit"))])):runtime.contextOrFrameLookup(context, frame, "symbol") + (lineno = 51, colno = 129, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "numFormat"), "numFormat", context, [0]))), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<strong>Total:</strong> <span>";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "grossCost") > 0?runtime.contextOrFrameLookup(context, frame, "symbol") + (lineno = 55, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "numFormat"), "numFormat", context, [runtime.contextOrFrameLookup(context, frame, "grossCost")])):"Free"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "discountAmount") && runtime.contextOrFrameLookup(context, frame, "discountAmount") > 0) {
output += "\n\t\t\t\t\t\t<div><strong>Discount: </strong> <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "symbol") + (lineno = 59, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "numFormat"), "numFormat", context, [runtime.contextOrFrameLookup(context, frame, "discountAmount")])), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") != 1 && runtime.contextOrFrameLookup(context, frame, "status") != 4 && runtime.contextOrFrameLookup(context, frame, "status") != 6 && runtime.contextOrFrameLookup(context, frame, "status") != 8) {
output += "\n\t\t\t\t\t\t\t\t<span class=\"cancelDiscount\">&nbsp;&nbsp;Cancel discount voucher</span>\n\t\t\t\t\t\t\t\t&nbsp;<span class=\"fa fa-question-circle-o\" title=\"Revoke the voucher for this purchase in order to reuse it on another purchase. (Only available on new and failed purchases)\"></span>\n\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "paid")) {
output += "\n\t\t\t\t\t\t<div><strong>Date Paid: </strong> <span>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "paid"), env.opts.autoescape);
output += "</span></div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\n\t\t\t\t<ul class=\"dropdown menu gearOptions\" data-dropdown-menu>\n\t\t\t\t  \t<li>\n\t\t\t\t    \t<a><i class=\"fa fa-cog\" aria-hidden=\"true\"></i></a>\n\t\t\t\t    \t<ul class=\"menu\">\n\t\t\t\t      \t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") != "Failed") {
output += "\n\t\t\t\t      \t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "instructionsUrl"), env.opts.autoescape);
output += "?id=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "purchaseId"), env.opts.autoescape);
output += "\" class=\"downloaderBtn\"><i class=\"fa fa-download fa-fw\" aria-hidden=\"true\"></i> ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 0?"Download invoice":"Download invoice"), env.opts.autoescape);
output += "</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") != "Failed" && (lineno = 82, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canBankDetails"), "canBankDetails", context, []))) {
output += "\n\t\t\t\t      \t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "instructionsUrl"), env.opts.autoescape);
output += "?id=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "purchaseId"), env.opts.autoescape);
output += "&bankDetails=true\" class=\"downloaderBtn\"><i class=\"fa fa-download fa-fw\" aria-hidden=\"true\"></i> ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 0?"Download invoice with banking details":"Download invoice with banking details"), env.opts.autoescape);
output += "</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "discountAmount") == runtime.contextOrFrameLookup(context, frame, "undefined") || runtime.contextOrFrameLookup(context, frame, "discountAmount") == 0) && runtime.contextOrFrameLookup(context, frame, "status") != 1 && runtime.contextOrFrameLookup(context, frame, "status") != 4 && runtime.contextOrFrameLookup(context, frame, "status") != 6 && runtime.contextOrFrameLookup(context, frame, "status") != 8) {
output += "\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a class=\"addDiscount\"><i class=\"fa fa-ticket fa-fw\" aria-hidden=\"true\"></i> Add discount voucher</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "status") == 0 || runtime.contextOrFrameLookup(context, frame, "status") == 2)) {
output += "\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a class=\"payOnline\"><i class=\"fa fa-credit-card fa-fw\" aria-hidden=\"true\"></i> Pay online</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
else {
if((runtime.contextOrFrameLookup(context, frame, "status") == 3)) {
output += "\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a class=\"payOnline\"><i class=\"fa fa-credit-card fa-fw\" aria-hidden=\"true\"></i> Retry online payment</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
;
}
output += "\n\n\t\t\t\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "status") == 0 || runtime.contextOrFrameLookup(context, frame, "status") == 2) && !runtime.contextOrFrameLookup(context, frame, "hidden")) {
output += "\n\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t<a class=\"hideItem\"><i class=\"fa fa-minus-square fa-fw\" aria-hidden=\"true\"></i> Hide from list</a>\n\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t<!-- Todo -->\n\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating")) {
output += "\n\t\t\t\t\t\t\t\t";
if((lineno = 112, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canChangeStatus"), "canChangeStatus", context, []))) {
output += "\n\t\t\t\t\t\t\t\t\t<li><a class=\"changeStatus\">Change status</a></li>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t\t";
if((lineno = 116, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canTransfer"), "canTransfer", context, [])) && (runtime.contextOrFrameLookup(context, frame, "status") == 4 || runtime.contextOrFrameLookup(context, frame, "status") == 6 || runtime.contextOrFrameLookup(context, frame, "status") == 8)) {
output += "\n\t\t\t\t\t\t\t\t\t<li><a class=\"transferPurchase\">Transfer</a></li>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t\t";
if((lineno = 120, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canRevoke"), "canRevoke", context, [])) && (runtime.contextOrFrameLookup(context, frame, "status") == 4 || runtime.contextOrFrameLookup(context, frame, "status") == 6 || runtime.contextOrFrameLookup(context, frame, "status") == 8)) {
output += "\n\t\t\t\t\t\t\t\t\t<li><a class=\"revokePurchase\">Revoke</a></li>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t\t";
if((lineno = 124, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canForceActivate"), "canForceActivate", context, [])) && runtime.contextOrFrameLookup(context, frame, "status") != 4 && runtime.contextOrFrameLookup(context, frame, "status") != 6 && runtime.contextOrFrameLookup(context, frame, "status") != 8) {
output += "\n\t\t\t\t\t\t\t\t\t<li><a class=\"forceActivate\">Force activate</a></li>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") == 0 && (lineno = 128, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canConfirm"), "canConfirm", context, []))) {
output += "\n\t\t\t\t\t\t\t\t\t<li><a class=\"confirmPayment\">Confirm</a></li>\n\t\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t<!-- /Todo -->\n\n\t\t\t\t    \t</ul>\n\t\t\t\t  \t</li>\n\t\t\t\t</ul>\n\t\t\t</div>\n\n\t\t</div>\n\n\t\t";
var t_1;
t_1 = 1;
frame.set("count", t_1, true);
if(frame.topLevel) {
context.setVariable("count", t_1);
}
if(frame.topLevel) {
context.addExport("count", t_1);
}
output += "\n\t\t";
var t_2;
t_2 = "";
frame.set("targetUser", t_2, true);
if(frame.topLevel) {
context.setVariable("targetUser", t_2);
}
if(frame.topLevel) {
context.addExport("targetUser", t_2);
}
output += "\n\n\t\t";
frame = frame.push();
var t_5 = runtime.contextOrFrameLookup(context, frame, "purchaseItems");
if(t_5) {var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("item", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
output += "\n\t\t";
var t_7;
t_7 = (lineno = 145, colno = 35, runtime.callWrap(runtime.memberLookup((runtime.memberLookup((t_6),"boughtFor")),"split"), "item[\"boughtFor\"][\"split\"]", context, [" "]));
frame.set("names", t_7, true);
if(frame.topLevel) {
context.setVariable("names", t_7);
}
if(frame.topLevel) {
context.addExport("names", t_7);
}
output += "\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "targetUser") != "" && runtime.contextOrFrameLookup(context, frame, "targetUser") != runtime.memberLookup((t_6),"targetUser")) {
output += "\n\t\t\t\t</table></div><!-- /pBody --> </div> <!-- /details -->\n\t\t\t";
;
}
output += "\n\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "targetUser") == "" || runtime.contextOrFrameLookup(context, frame, "targetUser") != runtime.memberLookup((t_6),"targetUser")) {
output += "\n\t\t\t\t";
var t_8;
t_8 = runtime.contextOrFrameLookup(context, frame, "count") + 1;
frame.set("count", t_8, true);
if(frame.topLevel) {
context.setVariable("count", t_8);
}
if(frame.topLevel) {
context.addExport("count", t_8);
}
output += "\n\t\t\t\t";
var t_9;
t_9 = runtime.memberLookup((t_6),"targetUser");
frame.set("targetUser", t_9, true);
if(frame.topLevel) {
context.setVariable("targetUser", t_9);
}
if(frame.topLevel) {
context.addExport("targetUser", t_9);
}
output += "\n\t\t\t\t<div class=\"details\">\n\t\t\t\t\t<div class=\"heading\">\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "buyer") != runtime.memberLookup((t_6),"targetUser")) {
output += "\n\t\t\t\t\t\t\t<div class=\"circle user-";
output += runtime.suppressValue(runtime.memberLookup((t_6),"colour"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<div class=\"circle user-main\">\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t\t<div class=\"inner\">\n\t\t\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("first").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),0))), env.opts.autoescape);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),"length") > 1 && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),1)),"length") > 0) {
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("first").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "names")),1))), env.opts.autoescape);
;
}
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t<span class=\"name\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"boughtFor")), env.opts.autoescape);
output += "</span>\n\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating") && (lineno = 169, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasTokensToRevoke"), "hasTokensToRevoke", context, [])) && (lineno = 169, colno = 68, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isPurchaseActive"), "isPurchaseActive", context, [])) && (lineno = 169, colno = 84, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canRevoke"), "canRevoke", context, []))) {
output += "\n\t\t\t\t\t\t\t<button type=\"button\" data-id=\"purchase_";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "purchaseId"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.memberLookup((t_6),"targetUser"), env.opts.autoescape);
output += "\" class=\"tiny button alert columns revoke-usertokens\">Revoke User Tokens</button>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"isGift")) {
output += "\n\t\t\t\t\t\t\t<div class=\"username\">( Purchased as gift )</div>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"schoolName")) {
output += "\n\t\t\t\t\t\t\t<div class=\"schoolName\">( School/Institution: <i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"schoolName")), env.opts.autoescape);
output += "</i> )</div>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_6),"username")),"length") > 0 && !runtime.memberLookup((t_6),"isFcUser")) {
output += "\n\t\t\t\t\t\t\t<div class=\"username\">( ";
if(runtime.memberLookup((t_6),"schoolName")) {
output += "miEbooks Username:";
;
}
else {
output += "Personal Account Username:";
;
}
output += " <i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"username")), env.opts.autoescape);
output += "</i> )</div>\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<div class=\"username\">( ";
if(runtime.memberLookup((t_6),"schoolName")) {
output += "miEbooks Email:";
;
}
else {
output += "Personal Account Email:";
;
}
output += " <i>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"email")), env.opts.autoescape);
output += "</i> )</div>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t<span class=\"slide\" data-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "purchaseId"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_6),"targetUser"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "count"), env.opts.autoescape);
output += "\">+</span>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"pBody\" id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "purchaseId"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_6),"targetUser"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "count"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<table class=\"itemTable\">\n\n\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating") && (lineno = 193, colno = 46, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasTokensToRevoke"), "hasTokensToRevoke", context, [])) && (lineno = 193, colno = 69, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isPurchaseActive"), "isPurchaseActive", context, [])) && (lineno = 193, colno = 85, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canRevoke"), "canRevoke", context, []))) {
output += "\n\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t<td colspan=\"2\" class=\"selectAll\" data-userid=";
output += runtime.suppressValue(runtime.memberLookup((t_6),"targetUser"), env.opts.autoescape);
output += ">Select All Items</td>\n\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "count") == 10) {
output += "\n\t\t\t\t\t";
var t_10;
t_10 = 1;
frame.set("count", t_10, true);
if(frame.topLevel) {
context.setVariable("count", t_10);
}
if(frame.topLevel) {
context.addExport("count", t_10);
}
output += "\n\t\t\t\t";
;
}
output += "\n\t\t\t";
;
}
output += " <!-- /boughtFor -->\n\n\t\t\t<tr class=\"product\">\n\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating") && (lineno = 206, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isPurchaseActive"), "isPurchaseActive", context, []))) {
output += "\n\t\t\t\t\t\t";
if(!runtime.memberLookup((t_6),"tokenRevoked")) {
output += "\n\t\t\t\t\t\t\t<td><input type=\"checkbox\" name=\"purchase_";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "purchaseId"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.memberLookup((t_6),"targetUser"), env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\"></td>\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t<td style=\"padding:0px !important\"></td>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t";
;
}
output += "\n\n\n\t\t\t\t<td class=\"title\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"bookTitle")), env.opts.autoescape);
output += "\">\n\n\t\t\t\t\t<table><tr><td style=\"padding-bottom: 0.5rem;\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"bookTitle")), env.opts.autoescape);
output += "\n\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"productType") == 1) {
output += "<br/><small>(";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"licenseName")), env.opts.autoescape);
output += ")</small>";
;
}
output += "\n\t\t\t\t\t</td></tr>\n\n\t\t\t\t\t<tr><td style=\"padding-top: 0;\">\n\t\t\t\t\t<!-- this displays for small screens -->\n\t\t\t\t\t<div class=\"hide-for-medium small-info\">\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating")) {
output += "\n\t\t\t\t\t\t\t<div>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"isbn")), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t\t<div>";
output += runtime.suppressValue(env.getFilter("versionFormat").call(context, runtime.memberLookup((t_6),"version")), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t<div>";
output += runtime.suppressValue((runtime.memberLookup((t_6),"amount") > 0?runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.memberLookup((t_6),"amount"):"Free"), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") == 4 || runtime.contextOrFrameLookup(context, frame, "status") == 6 || runtime.contextOrFrameLookup(context, frame, "status") == 8) {
output += "\n\t\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"tokenRevoked") == true) {
output += "\n\t\t\t\t\t\t\t\t\t<i class=\"fa fa-ban fa-fw\"></i>\n\t\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenSynced") == true) {
output += "\n\t\t\t\t\t\t\t\t\t<i class=\"fa fa-check fa-fw\"></i>\n\t\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenId") && runtime.memberLookup((t_6),"tokenSynced") == false) {
output += "\n\t\t\t\t\t\t\t\t\t<i class=\"fa fa-clock-o fa-fw\"></i>\n\t\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t\t<i class=\"fa fa-exclamation-triangle fa-fw\"></i>\n\t\t\t\t\t\t\t\t";
;
}
;
}
;
}
output += "\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"tokenRevoked") == true) {
output += "\n\t\t\t\t\t\t\t\t<i title=\"This ebook was revoked and is no longer active\"> Revoked</i>\n\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenSynced") == true) {
output += "\n\t\t\t\t\t\t\t\t<i title=\"This ebook is active\"> Activated</i>\n\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenId") && runtime.memberLookup((t_6),"tokenSynced") == false) {
output += "\n\t\t\t\t\t\t\t\t<i title=\"This ebook is not yet active\"> Not activated</i>\n\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t<i title=\"No valid token was created for this ebook\"> No token</i>\n\t\t\t\t\t\t\t";
;
}
;
}
;
}
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t</td></tr></table>\n\n\t\t\t\t</td>\n\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating")) {
output += "\n\t\t\t\t<td class=\"isbn show-for-medium\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_6),"isbn")), env.opts.autoescape);
output += "</td>\n\t\t\t\t<td class=\"version show-for-medium\">";
output += runtime.suppressValue(env.getFilter("versionFormat").call(context, runtime.memberLookup((t_6),"version")), env.opts.autoescape);
output += "</td>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t<td class=\"cost show-for-medium\">";
output += runtime.suppressValue((runtime.memberLookup((t_6),"amount") > 0?runtime.contextOrFrameLookup(context, frame, "symbol") + runtime.memberLookup((t_6),"amount"):"Free"), env.opts.autoescape);
output += "</td>\n\n\t\t\t\t<!-- item.productType == 1 only e-books -->\n\t\t\t\t";
if((runtime.contextOrFrameLookup(context, frame, "status") == 4 || runtime.contextOrFrameLookup(context, frame, "status") == 6 || runtime.contextOrFrameLookup(context, frame, "status") == 8) && runtime.memberLookup((t_6),"productType") == 1) {
output += "\n\t\t\t\t\t<td class=\"status show-for-medium\">\n\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") == 4 || runtime.contextOrFrameLookup(context, frame, "status") == 6 || runtime.contextOrFrameLookup(context, frame, "status") == 8) {
output += "\n\t\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"tokenRevoked") == true) {
output += "\n\t\t\t\t\t\t\t\t<i class=\"fa fa-ban fa-fw\"></i>\n\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenSynced") == true) {
output += "\n\t\t\t\t\t\t\t\t<i class=\"fa fa-check fa-fw\"></i>\n\t\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenId") && runtime.memberLookup((t_6),"tokenSynced") == false) {
output += "\n\t\t\t\t\t\t\t\t<i class=\"fa fa-clock-o fa-fw\"></i>\n\t\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t\t<i class=\"fa fa-exclamation-triangle fa-fw\"></i>\n\t\t\t\t\t\t\t";
;
}
;
}
;
}
output += "\n\t\t\t\t\t\t";
;
}
output += "\n\n\n\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"tokenRevoked") == true) {
output += "\n\t\t\t\t\t\t\t<i title=\"This ebook was revoked and is no longer active\"> Revoked</i>\n\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenSynced") == true) {
output += "\n\t\t\t\t\t\t\t<i title=\"This ebook is active\"> Activated</i>\n\t\t\t\t\t\t";
;
}
else {
if(runtime.memberLookup((t_6),"tokenId") && runtime.memberLookup((t_6),"tokenSynced") == false) {
output += "\n\t\t\t\t\t\t\t<i title=\"This ebook is not yet active\"> Not activated</i>\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<i title=\"No valid token was created for this ebook\"> No token</i>\n\t\t\t\t\t\t";
;
}
;
}
;
}
output += "\n\t\t\t\t\t</td>\n\n\t\t\t\t\t<td class=\"buttons\">\n\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"tokenSynced") == true && runtime.memberLookup((t_6),"tokenRevoked") == false && (lineno = 296, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canTransfer"), "canTransfer", context, []))) {
output += "\n\t\t\t\t\t\t\t<button data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\" type=\"button\" class=\"tiny transfer-token columns button\">Transfer</button>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
if((lineno = 300, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canGenerate"), "canGenerate", context, [])) && runtime.memberLookup((t_6),"tokenId") == runtime.contextOrFrameLookup(context, frame, "undefined")) {
output += "\n\t\t\t\t\t\t\t<button data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\" type=\"button\" class=\"tiny create-token success columns button\">Create Token</button>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
if((lineno = 304, colno = 19, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "canRevoke"), "canRevoke", context, [])) && runtime.memberLookup((t_6),"tokenRevoked") == false) {
output += "<button type=\"button\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\" class=\"tiny button alert columns revoke-token\">Revoke</button>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "impersonating")) {
output += "\n\t\t\t\t\t\t\t<i class=\"fa fa-info-circle tokenInfo\" aria-hidden=\"true\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\" style=\"font-size: 2rem;cursor: pointer;\" data-toggle=\"dropdown";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\"></i>\n\n\t\t\t\t\t\t\t<div class=\"dropdown-pane infoDiv\" id=\"dropdown";
output += runtime.suppressValue(runtime.memberLookup((t_6),"purchaseItemId"), env.opts.autoescape);
output += "\" data-dropdown data-position=\"bottom\" data-alignment=\"right\" style=\"width:400px;\"></div>\n\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t</td>\n\t\t\t\t\t<!-- status -->\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
if(runtime.memberLookup((t_6),"productType") != 1) {
output += "\n\t\t\t\t\t<td class=\"status\">\n\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "statusName") == "New") {
output += "\n\t\t\t\t\t\t\t<i>Awaiting Payment</i>\n\t\t\t\t\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "statusName") == "Successful") {
output += "\n\t\t\t\t\t\t\t<i class=\"fa fa-check fa-fw\"></i><i> Purchase Completed</i>\n\t\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t\t<i>Purchase ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "statusName"), env.opts.autoescape);
output += "</i>\n\t\t\t\t\t\t";
;
}
;
}
output += "\n\n\t\t\t\t\t</td>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t</tr> <!-- product -->\n\n\n\t\t";
;
}
}
frame = frame.pop();
output += " <!-- purchaseItems -->\n\n\t</div> <!-- itemBox -->\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var historyItem_oldRoot = historyItem_obj.root;
historyItem_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	historyItem_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var noHistoryItems_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<h4>No purchase history found.</h4>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var noHistoryItems_oldRoot = noHistoryItems_obj.root;
noHistoryItems_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	noHistoryItems_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"historyItem": new nunjucks.Template( {obj: historyItem_obj, type: "code"}, env),	"noHistoryItems": new nunjucks.Template( {obj: noHistoryItems_obj, type: "code"}, env),};
